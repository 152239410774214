.switch {
    @apply appearance-none
            w-9
            h-4
            rounded-full
            relative
            transition
            bg-gray-300
            cursor-pointer
            disabled:cursor-not-allowed
            disabled:opacity-70;
}

.switch:not(:disabled):checked,
.switch.always-active {
    @apply bg-pink-300;
}

.switch::after {
    @apply transition
            absolute
            rounded-full
            bg-gray-400;
    content: '';
    left: 0px;
    top: -0.0625rem;
    width: 1.125rem;
    height: 1.125rem;
    transform: translateX(var(--x, 0));
}

.switch:checked {
    --x: 1.25rem;
}

.switch:not(:disabled):checked::after,
.switch.always-active::after {
    @apply bg-pink-500;
    box-shadow: 0px 0px 4px theme('colors.pink.500');
}
