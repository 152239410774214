@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        scroll-behavior: smooth;
    }

    body {
        @apply text-neutral-900 font-normal;
        background-color: #fdfdfd;
    }

    ::selection {
        @apply bg-pink-500 text-white;
    }

    :focus-visible {
        outline: theme('colors.pink.500') auto 1px;
    }
}

@layer utilities {
    .w-text {
        max-width: 60ch;
    }

    .animate-underline {
        background-size: 0% 2px;
        background-repeat: no-repeat;
        background-position: left bottom;
        transition: background-size 300ms ease;
    }

    .animate-underline:hover {
        background-size: 100% 2px;
    }

    .bg-image-pink-500 {
        background-image: linear-gradient(theme('colors.pink.500'), theme('colors.pink.500'));
    }

    .drop-shadow-pink-md {
        filter: drop-shadow(0 0 4px theme('colors.pink.500'));
    }

    .drop-shadow-pink-sm {
        filter: drop-shadow(1px 1px 3px #ec4899a0);
    }

    .pixelated {
        image-rendering: pixelated;
    }
}
